import React from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ContentContainer from "../../../components/contentContainer"
import EventDetail from "../../../components/eventDetail"
import Loader from "../../../components/loader"
import { CURRENT_KEY_JSD, getPresentationById, getProgramData } from "../../../helpers/apiService"

class ProgramDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      error: false,
      loading: true,
    }
  }

  componentDidMount() {
    const state = this.props.location.state
    if (state && state.presentation) {
      this.setState({ data: state.presentation, loading: false, error: false })
    } else {
      getProgramData(CURRENT_KEY_JSD)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error()
          }
        })
        .then(data => {
          const presentation = getPresentationById(data, this.props.params.id)
          this.setState({ data: presentation, loading: false, error: false })
        })
        .catch(error => {
          this.setState({ error: true, loading: false })
        })
    }
  }

  render() {
    const { data, error, loading } = this.state
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Programm" />
        <div className="content">
          <div>
            {error && <div><p>Programm konnte nicht geladen werden</p></div>}
          </div>
          <div>
            {loading && <Loader />}
          </div>
          {(!error && !loading) && <ContentContainer paddingTop paddingMobile paddingMobileTop>
            <EventDetail title={data.title} subtitle={data.subTitle} eventtime={data.time}
                         speakers={data.speakerNames}
                         eventplace={data.roomName} html={data.description} isUrl={true} isHTML={true}
                         linkDestination="/day/programm">
              <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </EventDetail>
          </ContentContainer>}
        </div>
      </Layout>
    )
  }
}

export default ProgramDetails

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
